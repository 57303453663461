.contenedor-aceptar-opciones{
	position: fixed;
	width: 25%;
	background-color: white;
	border:solid;
	border-radius: 14px;
	border-color: #1B1C20;
	border-width: 1px;
	z-index: 3;
	text-align: center;
}
.div-contenedor-opciones{

	background-color: white;
	z-index: 3;
	max-height: calc(100vh - 520px);
	overflow-y: auto;
	text-align: center;
}
.contenedor-aceptar-opciones4 .div-contenedor-opciones{
	text-align: left;
	font-size: 15px;
	padding-left: 3px;
}
.div-contenedor-opciones::-webkit-scrollbar{
    width: 5px;
    height: 5px !important;
    background-color: rgba(255,255,255,0.2);
}

.div-contenedor-opciones::-webkit-scrollbar-thumb{
    background-color: rgba(0,0,0,0.6)
}
.button-aceptar-opciones{
    background-color: #1B1C20;
    border: none;
    color: #fff;
    border-radius: 10px;
    text-transform: uppercase;
    font-family: 'Roboto';
    margin: 0 5px;
    width: 120px;
    padding: 6px 0;
    cursor: pointer;
    transition: all ease .5s;
  margin-top: 0px;
  z-index: 4;
}
.div-contenedor-opciones p{
	margin-top: 0px;
	margin-bottom: 0px;
	padding: 9px 0px 9px 7px!important;
	cursor: pointer;
	text-align: left;
	font-size: 14px;
	font-weight: 400;
	color: #000
}

.div-contenedor-opciones p input{
	float: right;
	width: 30px;
}
.div-contenedor-opciones p:hover{
	background: #DEEBFF !important;
}

.principal-input-multiselect{
	text-align: center !important;
	cursor: default;
	width: 690px !important;
}

.arrow-down-select svg{
	position:absolute;
	margin-left: -40px;
	margin-top: -10px;
	font-size: 45px;
	color:#505050;
	pointer-events: none;
}

.contenedor-multiselect{
	position: relative;
	width: 700px !important;
}
#span-total-materias{
	position: absolute;
	left: 10px;
	top: 5px;
	font-size: 14px;
}
