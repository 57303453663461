.MuiInput-underline:before {
  content: none !important;
}

.MuiInputAdornment-positionEnd {
  margin-left: -45px !important;
}

.MuiIconButton-root {
  padding: 3px
}

.MuiInputBase-input {
  height: 23px !important;
  /* width: 120px !important; */
}

.MuiFormControl-root {
  border: 1px solid #ccc !important;
  padding: 0px 0px 3px 10px !important;
  border-radius: 5px !important;
  height: 30px;
}

.MuiDialog-root {
  z-index: 9999999 !important;
}

.nuevoUsuarioForm .MuiFormControl-root.date-picker,
.nuevoUsuarioForm-edit .MuiFormControl-root.date-picker {
  width: 100%;
  box-sizing: border-box;
  border-radius: 15px !important;
  background-color: #f9f9f9;
}

.nuevoUsuarioForm .date-picker .MuiInputBase-root input,
.nuevoUsuarioForm-edit .date-picker .MuiInputBase-root input {
  border: none;
  background-color: transparent;
  margin-top: 2px;
}

.nuevoUsuarioForm .date-picker .MuiButtonBase-root,
.nuevoUsuarioForm-edit .date-picker .MuiButtonBase-root {
  right: 5px;
  top: 0px;
  position: absolute;
  padding: 3px;
}

.nuevoUsuarioForm textarea.textarea-full,
.nuevoUsuarioForm-edit textarea.textarea-full {
  width: 100%;
  box-sizing: border-box;
  border-radius: 15px;
  background-color: #f9f9f9;
  border: 1px solid #ccc;
  resize: none;
}

.MuiPickersToolbar-toolbar {
  background-color: #7E7D82 !important
}

.MuiPickersClock-pin {
  background-color: #000 !important
}

.MuiPickersClockPointer-pointer {
  background-color: #000 !important
}

.MuiPickersClockPointer-noPoint {
  background-color: #000 !important
}

.MuiPickersClockPointer-thumb {
  border-color: #000 !important
}

.MuiButton-textPrimary {
  color: #000 !important
}

.MuiCheckbox-colorPrimary.Mui-checked {
  color: #7e7d82 !important
}

.MuiCheckbox-root {
  margin-left: -15px !important;
}

div[class^='makeStyles-root'] {
  z-index: 999999999999;
}