.select-modal__control {
    border-radius: 15px!important;
    background-color: #f9f9f9!important;
    min-height: 20px!important;
    font-size: 14px!important;
    padding-left: 7px!important;
    height: 33px!important;
}

.select-modal__value {
    margin-top: -4px;
}

.select-modal__value-container {
    position: initial!important;
}

.select-modal__control--is-focused .select-modal__placeholder {
    color: transparent;
}