footer {
    background-color: #ddd;
    width: 100%;
    display: block;
    padding: 5px 20px;
    font-size: 13px;
    height: 25px;
    box-sizing: border-box;
    position: absolute;
    bottom: 0;
}

footer span:first-child{
    float: left;
}

footer span:last-child{
    float: right
}