.info-boxes {
  max-height: 225px;
  overflow-y: auto;
}

.tab-filtros {
  align-items: center;
}

.tab-filtros>* {
  display: inline-block;
}

.tab-filtros p {
  margin: 15px 5px 15px 12px;
  font-size: 14px;
}

.tab-filtros select {
  height: 30px;
  padding: 0 20px 0 5px;
  border-radius: 3px;
}

.select-dashboard {
  min-width: 300px;
}

.select-search {
  z-index: 999999;
  min-width: 75px;
  font-size: 14px;
}

.mas-ancho {
  width: 70px !important;
}

.select-alcance {
  z-index: 999999;
  width: 180px;
  font-size: 14px;
}

.select-search__control {
  min-height: 30px !important;
  height: 30px !important;
  border-color: #aaa !important;
}

.select-search__indicators {
  padding: 6px 0px !important;
  height: 30px !important;
}

.select-search__indicator-separator {
  margin-right: 0px !important;
}

.select-search__indicator {
  padding: 2px !important;
}

.select-weeks {
  z-index: 9999999;
  width: 57px;
  font-size: 14px;
}

.select-tipos {
  z-index: 9999999;
  width: 120px;
  font-size: 14px;
}

.select-alcance-abs {
  font-size: 12px !important;
  position: absolute;
  width: 150px;
  background-color: white;
  z-index: 9999;
  top: 9px;
  left: 10px;
  pointer-events: none;
}

.error-input {
  border-color: #ff9494 !important;
}

.select-modal.error-input>div {
  border-color: #ff9494 !important;
}

.MuiSnackbar-root {
  z-index: 99999999 !important;
}

.inputs-modal input {
  background-color: #f9f9f9;
  border: 1px solid #ccc;
  width: 100%;
  box-sizing: border-box;
  padding: 7px 18px;
  border-radius: 15px;
  outline: none;
}

.contenedor-comparativa * {
  display: inline-block;
}

.contenedor-comparativa p {
  margin: 0;
  font-weight: 500;
  font-size: 15px;
  font-size: 13px;
}

@media only screen and (max-width: 1300px) {
  .contenedor-comparativa p {
    font-size: 15px;
  }
}

.contenedor-comparativa span {
  font-size: 18px;
}

.contenedor-materia {
  position: absolute;
}

.materia-left {
  bottom: 0;
  left: 0;
  text-align: right;
  width: 52%;
}

.materia-right {
  bottom: 0;
  right: 0;
  text-align: left;
  width: 45%;
}

.infobox .MuiSvgIcon-root:nth-child(2),
.infobox .MuiSvgIcon-root:nth-child(3) {
  width: 30px;
}

.infobox .MuiSvgIcon-root:nth-child(1) {
  fill: #27215f;
  font-size: 70px;
}

.search-input-desempeno-grupo {
  right: 40px;
  margin-top: 5px;
  position: absolute;
}

.contenendor-indicadores {
  width: 100% !important;
}

.franja-indicadores {
  display: inline-flex;
}

.info-boxes {
  width: 100% !important;
}

.info-boxes>div {
  display: flex;
  position: relative;
  margin-top: 25px;
}

.info-boxes>div>p {
  position: absolute;
  top: -45px;
  left: 10px;
  font-size: 22px;
}

#container-chart-bar {
  position: relative;
  width: 100%;
  margin: auto;
  margin-top: 40px;
}

#container-chart-bar canvas {
  height: calc(100vh - 490px) !important;
}

#container-chart-bar .contenedor-multiselect {
  position: absolute !important;
  left: 150px;
  top: -30px;
}

#container-chart-bar>span {
  position: absolute !important;
  left: 0px;
  top: -30px;
}

#container-chart-bar .contenedor-multiselect .contenedor-aceptar-opciones {
  width: 700px;
}

#container-chart-bar .contenedor-multiselect .contenedor-aceptar-opciones>input {
  width: 98%;
  border-radius: 50px;
  outline: none;
  border-width: 0px;
  border-bottom-width: 1px;
}