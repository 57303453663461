.LargeModal-grid {
  display: grid;
}

.LargeModal {
  background-color: #fff;
  min-width: 80%;
  width: 90%;
  outline: 0;
  margin: 0 auto;
  margin-top: 5vh;
  padding: 10px;
  position: relative;
}

.LargeModal h3 {
  margin: 0;
  background-color: #026eb7;
  color: #fff;
  padding: 10px 25px;
  font-size: 2em;
  font-weight: 400;
  border-bottom: 7px solid #f58a1d;
}

.LargeModal-container {
  border-left: 1px solid #ccc;
  border-right: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
}

.LargeModal-footer {
  position: absolute;
  bottom: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0;
  width: 100%;
}

.LargeModal-footer div {
  display: flex;
  align-items: center;
}

.LargeModal>svg {
  position: absolute;
  color: #ddd;
  top: 25px;
  right: 25px;
  cursor: pointer;
}

.LargeModal>svg:hover {
  color: #fff;
}

.btn-download {
  background-color: green;
  border: none;
  color: #fff;
  border-radius: 10px;
  text-transform: uppercase;
  font-family: "Roboto";
  margin: 0 5px;
  width: 120px;
  padding: 6px 0;
  cursor: pointer;
  transition: all ease 0.5s;
}

.btn-upload {
  background-color: green;
  border: none;
  color: #fff;
  border-radius: 10px;
  text-transform: uppercase;
  font-family: "Roboto";
  margin: 0 5px;
  width: 120px;
  padding: 6px 0;
  cursor: pointer;
  transition: all ease 0.5s;
}

.btn-danger {
  background-color: #e72400;
  border: none;
  color: #fff;
  border-radius: 10px;
  text-transform: uppercase;
  font-family: "Roboto";
  margin: 0 5px;
  width: 120px;
  padding: 6px 0;
  cursor: pointer;
  transition: all ease 0.5s;
}

.btn-success {
  background-color: green;
  border: none;
  color: #fff;
  border-radius: 10px;
  text-transform: uppercase;
  font-family: "Roboto";
  margin: 0 5px;
  width: 120px;
  padding: 6px 0;
  cursor: pointer;
  transition: all ease 0.5s;
}

.btn-primary {
  background-color: #026eb7;
  border: none;
  color: #fff;
  border-radius: 10px;
  text-transform: uppercase;
  font-family: "Roboto";
  margin: 0 5px;
  width: 120px;
  padding: 6px 0;
  cursor: pointer;
  transition: all ease 0.5s;
}

.btn-secondary {
  background-color: #aca7a1;
  border: none;
  color: #fff;
  border-radius: 10px;
  text-transform: uppercase;
  font-family: "Roboto";
  margin: 0 5px;
  width: 120px;
  padding: 6px 0;
  cursor: pointer;
  transition: all ease 0.5s;
}

.pos-abs-r {
  position: absolute;
  right: 2rem;
}