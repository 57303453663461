aside {
  background-color: #026eb7;
  width: 220px;
  height: calc(100vh - 90px);
  float: left;
  overflow-y: auto;
  overflow-x: hidden;
}

aside ul {
  padding: 0;
  margin: 0;
}

aside ul li a {
  font-size: 1.2em;
  color: #fff;
  text-decoration: none;
  display: block;
  padding: 10px 0 10px 20px;
  transition: all ease 0.5s;
  padding-left: 50px;
}

aside ul li a svg {
  width: 20px;
  margin-right: 10px;
  position: absolute;
  left: 20px;
}

aside ul li:hover > a {
  background-color: #c6c6c5;
  color: #1b1c20;
  border-left: 5px solid #f58a1d;
}

aside ul li:hover > a svg path {
  fill: black;
}

aside ul li.active > a {
  background-color: #c6c6c5;
  color: #1b1c20;
  border-left: 5px solid #f58a1d;
}

aside ul li.active > a svg path {
  fill: black;
}

aside ul .menu-title {
  font-size: 1.75em;
  text-transform: uppercase;
  color: #fff;
  padding: 15px 0 15px 20px;
}

aside ul .menu-title svg {
  width: 25px;
  margin-right: 10px;
}

.reportes-tab {
  background-color: #026eb7;
  width: 220px;
  right: 0;
  z-index: 999;
}

.reportes-tab a {
  font-size: 16px;
  display: block;
  padding: 6px 8px;
  margin-left: 20px;
}

.reportes-tab a:hover {
  background-color: #c6c6c5;
  border-left: 5px solid #f58a1d;
  color: #1b1c20;
}

.reportes-tab a.active {
  background-color: #c6c6c5;
  border-left: 5px solid #f58a1d;
  color: #1b1c20;
}
