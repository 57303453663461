.Calendar-Week {
  border: 1px solid black;
  padding: 2px 5px;
  background-color: black;
  color: white;
  text-align: center;
  font-size: 14px;
  position: relative;
  overflow-y: hidden;
}

.Cell-Min-Width {
  min-width: 110px;
  box-sizing: border-box;
}

.Calendar-Criterio {
  padding: 2px 5px;
  background-color: #aaa;
  border-bottom: 1px solid #777;
  border-left: 1px solid #777;
  font-size: 13px;
  height: 50px;
  overflow-y: auto;
}

.Calendar-Cell {
  border-bottom: 1px solid #999;
  border-right: 1px solid #999;
  cursor: pointer;
  box-sizing: border-box;
  text-align: center;
  padding-top: 15px;
  position: relative;
}

.Calendar-Cell.selected {
  background: #026eb7;
}

.Calendar-Cell.bloqueada {
  background-color: grey;
  cursor: not-allowed;
}

.Calendar-Cell.bloqueada2 {
  background-color: #ccc;
  cursor: not-allowed;
}

.Calendar-Cell.bloqueada2Selected {
  background-color: #6fab8f;
  cursor: not-allowed;
}

.Calendar-Cell.focus {
  filter: brightness(1.5);
  border: 2px solid #5b84ba;
  margin-left: -2px;
  margin-top: -2px;
}

.Calendar-Container {
  height: calc(100% - 200px);
  margin-top: 10px;
}

.Lista-Editable-Container {
  height: calc(100% - 100px);
  margin-top: 10px;
}

.inputs-calendar {
  padding: 0 5px;
}

.inputs-calendar label {
  font-size: 11px;
}

.inputs-calendar input {
  width: 100px;
  margin-top: 12px;
  font-size: 11px;
  padding: 5px 10px;
}

.Calendar-Container-Full {
  height: calc(100% - 10px);
}

.Calendar-Criterios {
  display: grid;
  grid-template-columns: 50px 200px;
  float: left;
  height: calc(100% - 5px);
  overflow-y: hidden;
}

.Calendar-Cells {
  display: grid;
  overflow: auto;
  height: calc(100% - 5px);
}

.Calendar-Headers {
  display: grid;
  overflow-x: hidden;
}

.vinotinto {
  background-color: rgb(192, 0, 0) !important;
}

.negro {
  background-color: #000;
  color: white;
  cursor: pointer;
  border: solid;
  border-width: 1px;
  border-radius: 7px;
  font-weight: 1000;
}

.blanco-ocultar {
  background-color: #fff;
  border-color: #fff;
  cursor: default;
}

.grey-header {
  background-color: rgb(199, 199, 199) !important;
  color: black;
}

.criterio-blanco {
  background-color: white !important;
  color: black;
  border-right: solid;
  border-width: 1px;
  border-color: #999;
}

.Calendar-Cells::-webkit-scrollbar-thumb {
  width: 30px;
}

.Encuadre-asignatrua {
  width: 100%;
  text-align: center;
  background-color: black;
  color: white;
}

.button-editar {
  position: absolute;
  margin-top: -10px;
}

.headers-lista-editar {}

.date-ranger-container {
  position: absolute;
  margin-top: -4px;
  left: 0px;
  cursor: pointer;
}

.date-ranger-container:hover {
  color: black;
  background-color: white;
}

.date-ranger-container-criterio {
  position: absolute;
  left: 0px;
  cursor: pointer;
}

.date-ranger-container-criterio:hover {
  color: white;
  background-color: black;
}