

.Coordinador-Menu-Table p {
    margin: 0 0px;
    font-size: 18px;
}

.Coordinador-Table-Box {
    border: 2px solid #999;
    margin: 5px;
    border-radius: 5px;
    height: 350px;
    padding: 5px 10px;
}

.arrow-box {
    position: relative;
    width: 20px;
}

.forward-btn {
    color: green;
    width: 50px!important;
    height: 30px!important;
    position: absolute;
    cursor: pointer;
    top: -15px;
    transition: all ease .5s;
}

.forward-btn:hover {
    filter: brightness(1.2);
}

.backward-btn {
    color: red;
    width: 50px!important;
    height: 30px!important;
    position: absolute;
    cursor: pointer;
    top: -15px;
    transform: rotate(180deg);
    transition: all ease .5s; 
}

.backward-btn:hover {
    filter: brightness(1.2)
}

.date-picker input{
    width:100% !important;
}