.NewModal {
  background-color: #fff;
  outline: 0;
  max-width: 800px;
  width: 90%;
  margin: 0 auto;
  margin-top: 5vh;
  padding: 10px;
  position: relative;
}

.NewModal h3 {
  margin: 0;
  background-color: #026eb7;
  color: #fff;
  padding: 10px 25px;
  font-size: 2em;
  font-weight: 400;
  border-bottom: 7px solid #f58a1d;
}

.NewModal-container {
  border-left: 1px solid #ccc;
  border-right: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  /* max-height: calc(100vh - 210px); */
}

.NewModal-footer {
  text-align: center;
  padding: 15px 0 15px 0;
  position: absolute;
  width: 100%;
  bottom: 0;
}

.NewModal .gray-btn {
  background-color: #aca7a1;
  border: none;
  color: #fff;
  border-radius: 14px;
  width: 120px;
  padding: 6px 0;
  text-transform: uppercase;
  font-family: "Roboto";
  margin: 0 5px;
  cursor: pointer;
  transition: all ease 0.5s;
}

.NewModal .gray-btn:hover {
  opacity: 0.75;
}

.NewModal .black-btn {
  background-color: #026eb7;
  border: none;
  color: #fff;
  border-radius: 17px;
  text-transform: uppercase;
  font-family: "Roboto";
  margin: 0 5px;
  width: 120px;
  padding: 6px 0;
  cursor: pointer;
  transition: all ease 0.5s;
}

.NewModal .delete-button {
  position: absolute;
  left: 30px;
  background-color: #e72400;
  border: none;
  color: #fff;
  border-radius: 10px;
  text-transform: uppercase;
  font-family: "Roboto";
  margin: 0 5px;
  width: 120px;
  padding: 6px 0;
  cursor: pointer;
  transition: all ease 0.5s;
}

.btn-danger {
  background-color: #e72400;
  border: none;
  color: #fff;
  border-radius: 10px;
  text-transform: uppercase;
  font-family: "Roboto";
  margin: 0 5px;
  width: 120px;
  padding: 6px 0;
  cursor: pointer;
  transition: all ease 0.5s;
}

.btn-success {
  background-color: #4caf50;
  border: none;
  color: #fff;
  border-radius: 10px;
  text-transform: uppercase;
  font-family: "Roboto";
  margin: 0 5px;
  width: 120px;
  padding: 6px 0;
  cursor: pointer;
  transition: all ease 0.5s;
}

.btn-primary {
  background-color: #026eb7;
  border: none;
  color: #fff;
  border-radius: 10px;
  text-transform: uppercase;
  font-family: "Roboto";
  margin: 0 5px;
  width: 120px;
  padding: 6px 0;
  cursor: pointer;
  transition: all ease 0.5s;
}

.pos-abs-r {
  position: absolute;
  right: 2rem;
}

.NewModal .black-btn:hover {
  opacity: 0.75;
}

.NewModal>svg {
  position: absolute;
  color: #ddd;
  top: 25px;
  right: 25px;
  cursor: pointer;
}

.NewModal>svg:hover {
  color: #fff;
}