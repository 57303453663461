header {
  height: 80px;
  width: 100%;
  border-bottom: 10px solid #f58a1d;
}

header .logo {
  height: 100%;
  width: 220px;
  display: flex;
  justify-content: center;
  align-items: center;
  float: left;
  box-sizing: border-box;
  padding: 15px;
}

header .logo img {
  height: 115%;
}

header .logo p {
  font-size: 20px;
  margin-left: 10px;
  border-left: 1px solid #e72400;
  height: 40px;
  padding-top: 15px;
  padding-left: 10px;
}

header .page-title h1 {
  text-transform: uppercase;
  font-size: 2em;
  padding-top: 27px;
  margin: 0;
  float: left;
  margin-left: 40px;
  color: #026eb7;
}

header .user-info {
  float: right;
  display: flex;
  margin-top: 15px;
  margin-right: 25px;
}

header .user-info > div {
  margin-right: 10px;
}

header .user-info .user-name {
  margin: 0;
  font-size: 1.5em;
  text-transform: uppercase;
  text-align: right;
}

header .user-info .user-rol {
  margin: 0;
  font-size: 1em;
  text-align: right;
}

header .user-info svg {
  width: 50px;
  height: 50px;
  border-radius: 100%;
  cursor: pointer;
  transition: all ease 0.5s;
}

header .user-info svg:hover {
  opacity: 0.75;
}

header .user-info img {
  width: 50px;
  height: 50px;
  border-radius: 100%;
  cursor: pointer;
  transition: all ease 0.5s;
  border: 2px solid #1b1c20;
}

header .user-info img:hover {
  opacity: 0.75;
}
