.nuevoUsuarioForm {
    display: flex;
    margin: 0 10px;
    padding-top: 10px;
}

.nuevoUsuarioForm-edit {
    margin: 0 10px;
    padding-top: 10px
}

.nuevoUsuarioForm .avatarForm, .nuevoUsuarioForm-edit .avatarForm {
    width: 208px;
    text-align: center;
    position: relative;
}

.user-layout {
    width: 100%;
    display: grid;
    grid-template-columns: 215px 1fr;
}

.nuevoUsuarioForm label, .nuevoUsuarioForm-edit label {
    font-size: 13px;
    font-weight: 600;
    display: block;
    margin: 5px 15px;
}

.nuevoUsuarioForm input, .nuevoUsuarioForm-edit input {
    background-color: #F9F9F9;
    border: 1px solid #ccc;
    width: 100%;
    box-sizing: border-box;
    padding: 7px 18px;
    border-radius: 15px;
    outline: none;
}

.nuevoUsuarioForm input::placeholder, .nuevoUsuarioForm-edit input::placeholder {
    font-family: 'Roboto';
}

.nuevoUsuarioForm input:focus::placeholder, .nuevoUsuarioForm-edit input:focus::placeholder {
    color: transparent;
}

.avatarForm img.avatar {
    height: 140px;
    width: 140px;
    border: 5px solid #1B1C20;
    border-radius: 100%;
    margin-top: 20px;
}

.editAvatar {
    position: absolute;
    width: 50px;
    height: 50px;
    top: 120px;
    right: 20px;
    cursor: pointer;
    transition: all ease .5s;
}

.editAvatar:hover {
    filter: brightness(2.5);
}

.select-search-box::after {
    width: 0px;
    height: 0px;
    border-top: 10px solid #777;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    position: absolute;
    top: 10px;
    right: 15px;
    content: ' ';
}

.select-search-box__options {
    margin: 0;
    padding: 0;
}

.select-search-box {
    position: relative;
}

.select-search-box--multiple {
    border-radius: 4px;
    box-shadow: 0 7px 14px 0 rgba(50, 50, 93, 0.1), 0 3px 6px 0 rgba(0, 0, 0, 0.07);
    overflow: hidden;
}

    input.select-search-box__search {
        line-height: 1;
    }

    .select-search-box--multiple .select-search-box__search {
        box-shadow: none;
    }

    .select-search-box--input .select-search-box__search {
        cursor: text;
    }

        .select-search-box__search:focus {
            cursor: text;
        }

    input[type='search']::-webkit-search-cancel-button, input[type='search']::-webkit-search-decoration {
        -webkit-appearance: none;
    }

    .select-search-box__select {
        display: none;
        position: absolute;
        left: 0;
        right: 0;
        background: #fff;
        overflow: auto;
        z-index: 100;
        min-height: 49px;
        border: 1px solid #ccc;
    }

    .select-search-box--multiple .select-search-box__select {
        display: block;
        position: static;
        border-top: 1px solid #eee;
        border-radius: 0;
        box-shadow: none;
    }

        .select-search-box__select--display {
            display: block;
        }

    .select-search-box__option {
        font-size: 14px;
        font-weight: 400;
        color: #616b74;
        padding: 5px 25px;
        border-top: 1px solid #eee;
        cursor: pointer;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        user-select: none;
    }

        .select-search-box__option:first-child {
            border-top: none;
        }

        .select-search-box__option--hover, .select-search-box__option:hover {
            background: #f4f7fa;
        }

        .select-search-box__option--selected {
            background: #54A0FF;
            color: #fff;
            border-top-color: #2184ff;
        }

            .select-search-box__option--selected:hover, .select-search-box__option--selected.select-search-box__option--hover {
                background: #2184ff;
                color: #fff;
                border-top-color: #2184ff;
            }

        .select-search-box__group {
            margin-top: 20px;
            padding-top: 20px;
            border-top: 1px solid #eee;
            position: relative;
        }

        .select-search-box__group-header {
            position: absolute;
            top: 0;
            left: 50%;
            transform: translate(-50%, -50%);
            background: white;
            padding: 0 10px;
            color: rgba(0, 0, 0, 0.5);
            font-size: 12px;
        }

.select-modal__value-container--is-multi {
    height: 30px;
    overflow: auto!important;
    margin-top: -6px;
}

.modal-switch {
    display: flex;
    align-items: center;
}

.modal-switch p {
    margin: 0;
}

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}
.centrar-texto{
    margin:auto !important;
    font-size:40px;
    text-align:center;
}
