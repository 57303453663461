.verificacion-modal {
  max-width: 1100px;
}

.contenedor-verificacion {
  width: 90%;
  margin: auto;
  height: 100%;
  overflow-x: auto;
}

.logo-from-lista img {
  width: 170px !important;
  float: left;
  display: block;
}

.logo-from-lista {
  width: 170px;
  float: left;
  display: block;
}

.encuadre {
  border: solid;
  border-width: 1px;
  margin-top: 15px;
}

.table-header table {
  padding: 0 0 !important;
  width: 100% !important;
  border-spacing: 0px;
  border-collapse: collapse;
}

.table-header td {
  border-width: 0.5px !important;
  width: 50% !important;
  border: solid;
  border-width: 0.5px !important;
  border-collapse: separate;
  margin: 0;
  padding: 0;
}

.header-verificacion {
  height: 230px;
  border: solid;
  border-width: 0.5px !important;
}

.header-verificacion h2 {
  color: #E72400;
  margin: 0px;
  float: left;
  margin-left: 40px;
  margin-top: 7.5px;
}

.franja-subtitulo {
  background-color: #7E7D82;
  border: solid;
  border-width: 0.5px !important;
  margin-top: 10px;
  text-align: center;
  font-weight: 5000;
  border-color: black;
  color: white;
}

.evaluacion-final-titulo {
  background-color: black;
  font-weight: 600;
  color: white;
  border: solid;
  border-width: 0.5px !important;
  text-align: center;
  border-color: black;
  width: 600px;
  float: right;
  margin-top: 30px;
}


.tabla-subtitulo {
  background-color: #CCCCCC;
  font-weight: 600;
  color: black;
  border: solid;
  border-width: 0.5px !important;
  text-align: center;
  border-color: black;
}

/*Estilos de la tabla verificacion*/


.tabla-verificacion table {
  width: 100% !important;
  padding: 0 0 !important;
  border-spacing: 0px;
  padding: 0 0 !important;
  border: solid !important;
  border-color: black;
}

.tabla-verificacion table th {
  background-color: #CCCCCC;
  border: solid;
  border-width: 0.5px !important;
  border-color: black;

}

.tabla-verificacion table th:nth-child(1) {
  width: 30%;
}

.tabla-verificacion>table>tr>th:nth-child(2) {
  width: 60%;
  background-color: red;
  border-color: black !important;
  color: white;
}

.tabla-verificacion table td {
  text-align: center;
  border: solid;
  border-width: 0.5px !important;
  border-color: black;
}

.semana-vacia {
  background-color: #80a042;
  border-color: #80a042 !important;
}

.sub-headers-lista {
  width: auto !important;
  background-color: red !important;
  color: white !important;
}

/*
.total-puntos:nth-last-child(3){
	background-color: #7cb342 !important;
}
.total-puntos:nth-last-child(2){
	background-color: #8F8E93 !important;
}
.total-puntos:nth-last-child(1){
	background-color: #CCCCCC !important;
}
*/

.tabla-evaluacion {
  margin-top: 0px !important;
}

.regenerar-btn {
  width: 150px !important;
}


.tabla-evaluacion table {
  float: right;
  border: solid;
  border-width: 0.5px;
  border-color: black !important;
  width: 600px !important;
  border-spacing: 0px;
}

.tabla-evaluacion table th {
  border: solid;
  border-width: 0.5px;
  border-color: black !important;
  background-color: red !important;
  color: white;
}

.tabla-evaluacion table td {
  text-align: center;
  border: solid;
  border-width: 0.5px;
  border-color: black !important;
  background-color: #c5cae9;
}


.tabla-valoracion table {
  float: right;
  margin-top: 10px;
  border: solid;
  border-width: 0.5px;
  border-color: black !important;
  width: 600px !important;
  border-spacing: 0px;
}

.tabla-valoracion table td,
.tabla-valoracion table th {
  text-align: center;
  border: solid;
  border-width: 0.5px;

}

.tabla-valoracion table th {
  background-color: #CCCCCC !important;
  color: black !important;
}





.subtitulo-encuadre {
  background-color: black;
  color: white;
}

.NewModal-container iframe {
  width: 100%;
  height: calc(100% - 65px);
}

.select-search__menu {
  z-index: 99999999999999 !important;
}

.editable-btn {
  position: absolute;
  left: 300px;
}