.masiva-titulo {
    margin: 0 20px;
    padding: 15px 0 5px 0;
}

.masiva-descripcion {
    margin: 0 20px;
    height: 150px;
    overflow: auto;
}

.masiva-botones {
    display: flex;
    text-align: center;
    width: 100%;
    justify-content: center;
}

.masiva-botones button {
    margin: 25px 10px 10px 10px;
    padding: 5px 30px;
}