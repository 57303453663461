.infobox {
    width: 100%;
    border: 2px solid #b3b3b3;
    margin: 0 10px;
    display: flex;
    text-align: center;
    justify-content: center;
    padding: 10px 0;
    height: 86px;
    box-sizing: border-box;
    position: relative;
}

.infobox svg {
    width: 40px;
}

.infobox .infobox-title {
    color: #7E7D82;
    font-size: 1.25em;
    margin: 0;
}

.infobox .infobox-total {
    color: #7e7d82;
    font-size: 2em;
    margin: 0;
    font-weight: 700;
}

.infobox > div {
    margin-left: 10px;
}