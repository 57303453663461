.tabs-list {
  display: flex;
}

.tab {
  border-radius: 20px 20px 0 0;
  padding: 5px 20px;
  cursor: pointer;
  transition: all ease 0.5s;
}

.tab:hover {
  z-index: 99;
}

.tab.active {
  z-index: 9;
  background-color: #27215f !important;
}

.tab:nth-child(1) {
  background-color: #026eb7;
  color: #fff;
}

.tab:nth-child(2) {
  background-color: #026eb7;
  color: #fff;
  margin-left: -10px;
}

.tab:nth-child(3) {
  margin-left: -10px;
  background-color: #026eb7;
  color: #fff;
}

.tab-content {
  height: calc(100% - 40px);
  border-left: 1px solid #b3b3b3;
  border-right: 1px solid #b3b3b3;
  border-bottom: 1px solid #b3b3b3;
  border-radius: 0 10px 10px 10px;
  overflow: hidden;
  padding: 0;
  margin-top: -1px;
}

.tab-header {
  height: 15px;
}
