#view-title-action {
  width: 50% !important;
  margin-right: 0px !important;
  display: inline-block;
}
.filtros-comparadores {
  float: right !important;
  display: inline-flex !important;
  color: black !important;
}
.filtros-comparadores .select-search__menu {
  max-height: 140px;
  display: flex;
  overflow-x: hidden !important;
}

.filtros-comparadores p {
  margin: 0px;
  color: white;
}
#accion-comparar {
  width: 20% !important;
  margin-left: 0px !important;
  float: right;
  display: inline-block;
  text-align: center;
  cursor: pointer;
}
#accion-clear {
  max-width: 20px;
  max-height: 20px;
  margin-left: 0px !important;
  float: right;

  padding: 6px 8px 8px 6px;
  border-radius: 300px;
  cursor: pointer;
}
#accion-clear svg {
  margin: 0px !important;
}
#accion-comparar:hover {
  background-color: #0289e4;
}
.contenedor-franja-header {
  width: 100%;
}
