.contenedor-aceptar-opciones{
	position: fixed;
	width: 25%;
	max-width: 275px;
	background-color: white;
	border:solid;
	border-radius: 14px;
	border-color: #1B1C20;
	border-width: 1px;
	z-index: 10;
	max-height: 330px;
	text-align: center;
}
.contenedor-aceptar-opciones2{
	position: fixed;
	width: 25%;
	max-width: 385px;
	background-color: white;
	border:solid;
	border-radius: 14px;
	border-color: #1B1C20;
	border-width: 1px;
	z-index: 10;
	max-height: 330px;
	text-align: center;
}
.contenedor-aceptar-opciones3{
	position: fixed;
	width: 25%;
	max-width: 14%;
	background-color: white;
	border:solid;
	border-radius: 14px;
	border-color: #1B1C20;
	border-width: 1px;
	z-index: 10;
	max-height: 330px;
	text-align: center;
}
.contenedor-aceptar-opciones4{
	position: fixed;
	width: 50%;
	max-width: 480px;
	background-color: white;
	border:solid;
	border-radius: 14px;
	border-color: #1B1C20;
	border-width: 1px;
	z-index: 10;
	max-height: 330px;
	text-align: center;
}
.div-contenedor-opciones{

	background-color: white;
	z-index: 10;
	max-height: 270px;
	overflow-y: auto;
	text-align: center;
}
.contenedor-aceptar-opciones4 .div-contenedor-opciones{
	text-align: left;
	font-size: 15px;
	padding-left: 3px;
}
.div-contenedor-opciones::-webkit-scrollbar{
    width: 5px;
    height: 5px !important;
    background-color: rgba(255,255,255,0.2);
}

.div-contenedor-opciones::-webkit-scrollbar-thumb{
    background-color: rgba(0,0,0,0.6)
}
.button-aceptar-opciones{
    background-color: #1B1C20;
    border: none;
    color: #fff;
    border-radius: 10px;
    text-transform: uppercase;
    font-family: 'Roboto';
    margin: 0 5px;
    width: 120px;
    padding: 6px 0;
    cursor: pointer;
    transition: all ease .5s;
  margin-top: 0px;
  z-index: 10;
}
.div-contenedor-opciones p{
	margin-top: 0px;
	margin-bottom: 0px;
	padding-top: 5px;
	padding-bottom: 5px;
	cursor: pointer;
}

.div-contenedor-opciones p input{
	float: right;
	width: 30px;
}
.div-contenedor-opciones p:hover{
	background: #7E7D82
}

.principal-input-multiselect2{
	text-align: center !important;
	cursor: default;
	background-color: #f9f9f9 !important;
	/* z-index: 2 !important; */
	position: relative;
	/* background-color: green !important; */
}

.arrow-down-select2{
	position:absolute;
	margin-left: -36px;
	/* margin-top: -26px; */
	display: inline;
	/* float: right; */
	/* display: contents; */
	/* background-color: blue; */
	/* margin-top: -7px; */
	/* font-size: 45px;  */
}


.contenedor-multiselect2{
	position: relative;
	/* background-color: blue; */
	/* z-index: 50; */
	/* border: 1px solid  hsl(0,0%,80%); */
}
#span-total-materias{
	position: absolute;
	left: 10px;
	top: 5px;
	font-size: 14px;
}
.principal-input-multiselect2{
	text-align: center;
	cursor: default;
}

.select-modal2 {
    border-radius: 15px!important;
    background-color: #f9f9f9!important;
    min-height: 20px!important;
    font-size: 14px!important;
    padding-left: 7px!important;
    height: 33px!important;
}

.select-modal2 {
    margin-top: -4px;
}

.select-modal2 {
    position: initial!important;
}

.select-modal2--is-focused .select-placeholder {
    color: transparent;
}
