.date-year {
    height: 30px;
    box-sizing: border-box;
    width: 60px;
    padding-top: 0px;
    margin-top: 8px!important;
}

.date-year input {
    padding-top: 3px;
    padding-bottom: 3px;
}

.select-docente {
    z-index: 9999999;
    width: 150px;
    font-size: 14px;
}

.select-docente-largo {
    z-index: 9999999;
    width: 140px;
    font-size: 13px
}

@media screen and (max-width: 1230px){
    .select-docente-largo {
        width: 150px
    }
}

@media screen and (max-width: 1150px){
    .select-docente-largo {
        width: 120px
    }
}

textarea.input-clave {
    background-color: #f9f9f9;
    border: 1px solid #ccc;
    width: 100%;
    box-sizing: border-box;
    padding: 7px 18px;
    border-radius: 15px;
    outline: none;
    resize: none;
    height: 150px
}

.select-search__menu{
    text-align: center;
}
.select-search__single-value ,.select-search__value-container{
    text-align: center;
}

#root > div[class*="jss"]{
    z-index:99999999 !important
}