.LoginPage {
  background-color: #026eb7;
  width: 100%;
  height: 100vh;
  box-sizing: border-box;
}

.LoginPage > div {
  height: 100%;
  display: flex;
  align-items: center;
}

.LoginBox {
  width: 400px;
  height: 300px;
  margin: 0 auto;
  text-align: center;
  padding: 30px;
}

.LoginBox > img {
  width: 100%;
  margin-bottom: 40px;
}

.LoginBox label {
  display: block;
  text-align: left;
  font-size: 1.2em;
}

.LoginBox input {
  width: 100%;
  margin-bottom: 10px;
  padding: 4px 20px;
  box-sizing: border-box;
  border-radius: 15px;
  border: 1px solid #ccc;
  font-size: 16px;
}

.button {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  height: 50px;
  background-color: #0007;
  outline: none;
  border: none;
  cursor: pointer;
  border-radius: 13px;
  padding: 0 30px;
}

.button > p {
  font-size: 17px;
  color: #fff;
  margin-right: 10px;
}

.LoginBox select {
  width: 100%;
  border-radius: 15px;
  margin-bottom: 10px;
  padding: 4px 20px;
  box-sizing: border-box;
  border: 1px solid #ccc;
  font-size: 16px;
}

.input-error {
  border-color: #a50000 !important;
}

.error-message {
  color: #a50000;
}

.MuiCircularProgress-colorPrimary {
  color: #a50000 !important;
}
