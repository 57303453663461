.opciones-historial div>span {
  font-weight: 700;
  margin-right: 30px;
  margin-left: 25px;
}

.opciones-historial label {
  margin-right: 15px;
}

.opciones-historial select {
  margin-right: 30px;
  padding: 5px 20px;
  border-radius: 15px;
}

.enviado {
  font-weight: 700;
  color: #51AD32;
}

.no-enviado {
  font-weight: 700;
  color: #AC1917;
}

.row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  margin: 0 5px;
  /* min-height: 30px; */
}

.row div {
  font-size: 11px;
  margin: 0 2px;
}

.opciones-bar {
  margin-bottom: 5px;
  min-height: 45px;
}

.opciones-bar>* {
  display: inline-block
}

.opciones-bar label {
  margin: 0 5px;
}

.opciones-bar span {
  margin: 0 5px
}

.opciones-bar div>span {
  margin: 0 5px 0 0px
}

.opciones-bar-reporte .tab-filtros>div>p {
  margin: 2px 0;
}

.opciones-bar-reporte .tab-filtros>div {
  margin-right: 5px;
}

.opciones-bar-reporte .date-year {
  display: block !important;
  margin-top: 0px !important
}


.opciones-bar-filter .tab-filtros>div>p {
  margin: 2px 0;
}

.opciones-bar-filter .tab-filtros>div {
  margin-right: 5px;
}

.opciones-bar-filter .date-year {
  display: block !important;
  margin-top: 0px !important
}


.fecha-historial .MuiFormControl-root {
  height: 25px;
  margin-top: -6px;
  width: 95px;
}

.fecha-historial input {
  padding-top: 3px
}