.subtitle {
  font-weight: bold;
}

code {
  font-size: 12px;
}

#layout-documentacion button,
input[type="submit"],
input[type="reset"] {
  background: none;
  color: inherit;
  border: 2px solid #ccc;
  padding: 0;
  font: inherit;
  cursor: pointer;
  border-radius: 5px;
  padding: 5px;
  margin: auto;
}